const heroImage = 'https://jasonduvenage.com/adoozy/assets/img/content/partners.jpeg';
const benefits1 = 'https://jasonduvenage.com/adoozy/assets/img/content/partners-benefits-1.jpg';
const benefits2 = 'https://jasonduvenage.com/adoozy/assets/img/content/partners-benefits-2.jpg';
const benefits3 = 'https://jasonduvenage.com/adoozy/assets/img/content/partners-benefits-3.jpg';

export const hero = {
  first_line: "Grow Your",
  second_line: "Business",
  image: heroImage,
  cta_text: "Enquire With Us",
  cta_link: ""
};

export const intro = "When consumers’ phone batteries die, they often look to businesses to provide a charging solution, Adoozy Power takes away this inconvenience while making sure your customers can stay powered up while visiting your business.";

export const benefits = [
  {
    title: "Increase Foot Traffic",
    description: 'End-users visit businesses that have the Adoozy Power Towers to rent a power bank and end-up buying something else or becoming aware of other services. Adoozy location marketing activities also bring more awareness and therefore foot traffic to your business.',
    image: benefits1
  },
  {
    title: "Increase Customer Spend",
    description: "Consumers are known to stay longer at establishments when they aren't deterred by issues such as low battery power which prohibit them from staying connected to family, work or emergency contacts.",
    image: benefits2
  },
  {
    title: "Improve Experience",
    description: "Phone usage is a way of life and consumers have a better experience (and share about it) when they have access to use their phones without worry. This value-added service would add much needed convenience to patrons.",
    image: benefits3
  },
];

export const form = {
  title: "Partner With Us",
  submit_label: "Submit",
  fields: [
    {
      type: 'group',
      fields: [
        {
          type: 'text',
          label: 'First Name',
          name: 'first_name',
          required: true
        },
        {
          type: 'text',
          label: 'Surname',
          name: 'last_name',
          required: true
        },
      ]
    },
    {
      type: 'group',
      fields: [
        {
          type: 'email',
          label: 'Email',
          name: 'email',
          required: true
        },
        {
          type: 'tel',
          label: 'Phone',
          name: 'phone',
          required: false
        },
      ]
    },
    {
      type: 'group',
      fields: [
        {
          type: 'text',
          label: 'Company',
          name: 'company',
          required: false
        },
        {
          type: 'select',
          label: 'Business Type',
          required: false,
          name: 'business_type',
          options: [
            {
              value: "Event",
              label: "Event"
            },
            {
              value: "Bar",
              label: "Bar"
            },
            {
              value: "Restaurant",
              label: "Restaurant"
            },
            {
              value: "Retail",
              label: "Retail"
            },
            {
              value: "Other",
              label: "Other"
            }
          ]
        },
      ]
    },
    {
      type: 'textarea',
      label: 'Comment',
      name: 'comment',
      required: false
    }
  ]
}

export const partners = [
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/sasol.png',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/emperors-palace.png',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/gemelli.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/sandton-city-mall.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/rosebank-mall.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/altar-bar.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/bedford-shopping-centre.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/cafe-la-plage.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/cotton-lounge.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/cuisino.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/east-gate-mall.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/gateway.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/julios.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/mozambik.png',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/platinum-belt.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/si-cantina-sociale.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/solo.jpeg',
    name: ''
  },
  {
    image: 'https://jasonduvenage.com/adoozy/assets/img/content/partners-logos/the-valley.jpeg',
    name: ''
  }
];
