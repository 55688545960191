import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

import './Loader.scss';
import adoozylogo from 'assets/img/AdoozyLogo.gif';

const transition = {
  duration: 0.6,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const loadedTransition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
  repeat: Infinity,
  repeatType: 'reverse',
}

export default function Loader(props) {
  const controls = useAnimation();

  useEffect(() => {
    const timer = setTimeout(() => {
      controls.start({
        scale: 1.1,
        transition: loadedTransition,
      });
    }, 7000);

    return () => {
      clearTimeout(timer);
    };
  }, [controls]);

  return (
      <img width="20%" src={adoozylogo}/>
  );
}
