import { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import AdoozyPowerProductsItem from './AdoozyPowerProductsItem';
import Contact from 'components/ContactComponent/Contact';
import { Mobile, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import './AdoozyPowerProducts.scss';

// import arrowIcon from 'assets/svg/right-arrow-black.svg';
import rightArrowIcon from 'assets/svg/right-arrow-icon.svg';
import leftArrowIcon from 'assets/svg/left-arrow-icon.svg';

const form = {
  title: "Enquire With Us",
  submit_label: "Submit",
  fields: [
    {
      type: 'group',
      fields: [
        {
          type: 'text',
          label: 'First Name',
          name: 'first_name',
          required: true
        },
        {
          type: 'text',
          label: 'Surname',
          name: 'last_name',
          required: true
        }
      ]
    },
    {
      type: 'group',
      fields: [
        {
          type: 'email',
          label: 'Email',
          name: 'email',
          required: true
        },
        {
          type: 'tel',
          label: 'Phone',
          name: 'phone',
          required: false
        }
      ]
    },
    {
      type: 'textarea',
      label: 'Comment',
      name: 'comment',
      required: false
    }
  ]
};

const enquiryFormContainerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 999,
  width: '100vw',
  height: '100vh',
  backgroundColor: '#333333'
}

export default function AdoozyPowerProducts(props) {
  const [activeProduct, setActiveProduct] = useState(0);
  const [isFirstProduct, setIsFirstProduct] = useState(true);
  const [isLastProduct, setIsLastProduct] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isEnquiryFormActive, setIsEnquiryFormActive] = useState(false);
  const [subheading, setSubheading] = useState('');

  const ref = useRef();

  useEffect(() => {
    if(activeProduct === 0) {
      setIsFirstProduct(true);
    } else {
      setIsFirstProduct(false);
    }

    if(activeProduct === props.items.length-1) {
      setIsLastProduct(true);
    } else {
      setIsLastProduct(false);
    }

    setOffset( activeProduct * ( 
      ref.current.offsetWidth
      + parseFloat(getComputedStyle(ref.current).getPropertyValue('margin-right'))
    ));
  }, [activeProduct]);

  const goToPrevProduct = () => {
    if(activeProduct > 0) setActiveProduct(prevActiveProduct => prevActiveProduct-1);
  }

  const goToNextProduct = () => {
    if(activeProduct < props.items.length-1) setActiveProduct(prevActiveProduct =>  prevActiveProduct+1);
  }

  const toggleIsEnquiryFormActive = (name) => {
    setSubheading(name);
    setIsEnquiryFormActive(prevIsActive => !prevIsActive);
  }

  return (
    <div className="adoozy_power_products" style={{ paddingTop: '75px', paddingBottom: '100px' }}>
      <h2 className="adoozy_power_products__title">
        Products
      </h2>
      <div className="adoozy_power_products__product_list">
        <button onClick={goToPrevProduct} className="adoozy_power_products__product_list__button v_prev" style={{ opacity: isFirstProduct ? '0.2' : '1' }}>
          <img src={leftArrowIcon} alt="Previous" width="25" />
        </button>
        <ul ref={ref} className="adoozy_power_products__product_list__items" style={{ transform: `translateX(-${offset}px` }}>
          {props.items.map((item, index) => <AdoozyPowerProductsItem key={index} toggleIsEnquiryFormActive={toggleIsEnquiryFormActive} name={item.title} description={item.metadata.description} image={item.metadata.image.sizes.large} link={item.metadata.link} />)}
        </ul>
        <button onClick={goToNextProduct} className="adoozy_power_products__product_list__button v_next" style={{ opacity: isLastProduct ? '0.2' : '1' }}>
          <img src={rightArrowIcon} alt="Next" width="25" />
        </button>
      </div> 
      <AnimatePresence>
        {isEnquiryFormActive &&
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ y: '-100%' }}
            transition={{ delay: 0.4, duration: 1, ease: [0.43, 0.13, 0.23, 0.96], stiffness: 100 }}
            style={enquiryFormContainerStyle}>
            <Contact title={form.title} subheading={subheading} fields={form.fields} submit_label={form.submit_label} toggleIsContactActive={toggleIsEnquiryFormActive} />
          </motion.div>
        }
      </AnimatePresence>
    </div>
  );
}
