import { useState, useEffect, useRef } from 'react';
import './Loading.scss';
import adoozylogo from 'assets/img/Rotate.mp4';

export default function Footer(props) {

  const vidRef=useRef();
    useEffect(() => { vidRef.current.play(); },[]);

    const videoUrl = adoozylogo;

    return (
        <div className="video-container2">
          {videoUrl ? (
            <video className='background-video2' loop muted autoPlay src={videoUrl} type="video/mp4" ref={vidRef} />
          ) : (
            <p>No video available</p> // Fallback if no video URL is provided
          )}
        </div>
    );
}
