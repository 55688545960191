import { useEffect, useState, useRef } from 'react';
import { motion } from "framer-motion";

import AdoozyPowerPricingItem from './AdoozyPowerPricingItem';

import './AdoozyPowerPricing.scss';

import rightArrowIcon from 'assets/svg/right-arrow-icon_pricing.svg';
import leftArrowIcon from 'assets/svg/left-arrow-icon_pricing.svg';
import bankingicon from 'assets/img/Visa-MasterCard.png';

const transition = {
  duration: 0.4,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function AdoozyPowerPricing(props) {
  const [activeProduct, setActiveProduct] = useState(0);
  const [isFirstProduct, setIsFirstProduct] = useState(true);
  const [isLastProduct, setIsLastProduct] = useState(false);
  const [offset, setOffset] = useState(0);
  const ref = useRef();

  useEffect(() => {
    if(activeProduct === 0) {
      setIsFirstProduct(true);
    } else {
      setIsFirstProduct(false);
    }

    if(activeProduct === props.items.length-1) {
      setIsLastProduct(true);
    } else {
      setIsLastProduct(false);
    }

    setOffset( activeProduct * ( 
      ref.current.offsetWidth
      + parseFloat(getComputedStyle(ref.current).getPropertyValue('margin-right'))
    ));
  }, [activeProduct]);

  const goToPrevProduct = () => {
    if(activeProduct > 0) setActiveProduct(prevActiveProduct => prevActiveProduct-1);
  }

  const goToNextProduct = () => {
    if(activeProduct < props.items.length-1) setActiveProduct(prevActiveProduct =>  prevActiveProduct+1);
  }

  return (
    <div className="adoozy_power_pricing">
      <h2 className="adoozy_power_pricing__title">
        <motion.span
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={transition}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { x: '-100%', opacity: 0 }
          }}
        >
          Pricing
        </motion.span>
      </h2>
      <p className="adoozy_power_pricing__subheading">
        <motion.span
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={transition}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { x: '-100%', opacity: 0 }
          }}
        >
          Prepaid Rental Rates
        </motion.span>
      </p>
      <div className="adoozy_power_pricing__list">
        <button onClick={goToPrevProduct} className="adoozy_power_pricing__list__button v_prev" style={{ opacity: isFirstProduct ? '0.2' : '1' }}>
          <img src={leftArrowIcon} alt="Previous" width="25" />
        </button>
        <ul
          ref={ref}
          className="adoozy_power_pricing__list__items"
          style={{ transform: `translateX(-${offset}px` }}
        >
          {props.items.map((item, index) => <AdoozyPowerPricingItem key={index} index={index} title={item.title} description={item.description} price={item.price} image={item.image} />)}
        </ul>
        <button onClick={goToNextProduct} className="adoozy_power_pricing__list__button v_next" style={{ opacity: isLastProduct ? '0.2' : '1' }}>
          <img src={rightArrowIcon} alt="Next" width="25" />
        </button>
      </div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={transition}
        variants={{
          visible: { opacity: 1, x: 0 },
          hidden: { x: '-100%', opacity: 0 }
        }} >
         <img className='adoozy_power_pricing__list__bankingimage'  src={bankingicon} />
      </motion.div>
    </div>
  );
}
