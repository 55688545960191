import { useState, useEffect } from 'react';

import './ForMyEvents.scss';

import Page from 'components/PageComponent/Page';
import Loading from 'components/LoadingComponent/Loading';
import CommingSoon from 'components/CommingSoonComponent/CommingSoon';
import Header from 'components/HeaderComponent/Header';

function ForMyEventsView() {
    const slug = 'formyevents';
    const [data, setData] = useState(null);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data));

    }, []);

    if (!data) {
        return <Loading />;
    }

    return (
        <div>
             <Header showHamburger={false}/>
            <CommingSoon sendData={data.metadata}/>
        </div>
    );
}

export default ForMyEventsView;

