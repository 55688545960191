import { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

import Form from 'components/FormComponent/Form';

import './Contact.scss';

import logo from 'assets/svg/logo.svg';
import closeIcon from 'assets/svg/close-icon.svg';

const navVariants = {
  initial: {
    marginLeft: '-50px',
    opacity: 0
  },
  exit: {
    marginLeft: '-50px',
    opacity: 0
  }
}

const transition = {
  duration: 1,
  stiffness: 50,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const logoTransition = {
  duration: 0.4,
  type: 'spring',
  // stiffness: 100,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const logoVariants = {
  initial: {
    scale: 0.5,
    opacity: 0
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      ...logoTransition,
      delay: 1
    }
  },
  whileHover: { scale: 1.1 }
};

const navTransition = {
  duration: 1,
  delay: 2,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function Contact(props) {
  const controls = useAnimation();

  useEffect(() => {
    controls.start((i) => ({
      marginLeft: 0,
      opacity: 1,
      duration: 0.5,
      transition: { delay: i * 0.5 }
    }));
  }, []);

  function close() {
    props.toggleIsContactActive();
  }

  return (
    <div className="contact">
      <div className="contact__header">
        <Link className="contact__header__logo" to="/">
          <motion.img
            variants={logoVariants}
            initial="initial"
            animate="animate"
            transition={logoTransition}
            whileHover="whileHover"
            className="contact__header__logo__image"
            src={logo}
            width=""
            height=""
            alt="Adoozy Power On The Go"
          />
        </Link>
        <div className="contact__header__content">
          <motion.button
            variants={logoVariants}
            initial="initial"
            animate="animate"
            transition={logoTransition}
            whileHover="whileHover"
            onClick={close}
            className="contact__header__content__close_btn"
          >
            <img className="contact__header__content__close_btn__image" src={closeIcon} width="" height="" alt="Close Menu" />
          </motion.button>
        </div>
      </div>
      <div className="contact__form">
        <Form subject={'New Website Enquiry'} title={props.title} subheading={props.subheading} submit_label={props.submit_label} fields={props.fields} />
      </div>
      {/* <div className="contact__footer">
        <ul className="contact__footer__contact_info">
          <li className="contact__footer__contact_info__item">Email: <a href="mailto:support@adoozy.co.za">support@adoozy.co.za</a></li>
          <li className="contact__footer__contact_info__item">Call: <a href="tel:27105491919">(010) 549 1919</a></li>
        </ul>
      </div> */}
    </div>
  );
}
