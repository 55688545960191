import { useEffect, useState, useRef } from "react";
import { scrollToY } from 'components/UtilsComponent/Utils.js';

import './PageMenu.scss';

export default function PageMenu(props) {
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef();

  useEffect(() => {
    window.addEventListener('scroll', updateIsSticky);

    return () => {
      window.removeEventListener('scroll', updateIsSticky);
    };
  }, []);

  const goTo = (sectionRef) => {
    scrollToY(window.scrollY + sectionRef.current.getBoundingClientRect().top - ref.current.clientHeight, 1500, 'easeInOutQuint');
  }

  const updateIsSticky = (e) => {
    window.scrollY >= window.scrollY + ref.current.getBoundingClientRect().top ? setIsSticky(true) : setIsSticky(false);
  }
  

  return (
    <div ref={ref} className="page_menu">
      <div className="page_menu__container" style={isSticky ? { position: 'fixed' } : { display: 'none' }}>
        <ul className="page_menu__list">
          {props.sections.map((menuItem, index) => {
            return (
              <li key={index} onClick={e => goTo(menuItem.ref)} className="page_menu__list__item">
                <span className="page_menu__list__item__link">
                  {menuItem.name}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
