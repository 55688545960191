import { useState, useCallback, memo, useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { motion } from 'framer-motion';

import './Map.scss';

import closeMenuIcon from 'assets/svg/close-icon.svg';

const containerStyle = {
  width: '100%',
  height: '100%',
  display: 'block'
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  // styles: [{
  //   stylers: [{
  //     saturation: -100
  //   }]
  // }]
};

const logoTransition = {
  duration: 0.4,
  type: 'spring',
  ease: [0.43, 0.13, 0.23, 0.96]
}

const logoVariants = {
  initial: {
    scale: 0.5,
    opacity: 0
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      ...logoTransition,
      delay: 1
    }
  },
  whileHover: { scale: 1.1 }
};

function Map(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map)
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props.center}
      zoom={7}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
    >
      {props.markers.map((marker, index) => <Marker key={index} position={{ lat: marker.Latitude, lng: marker.Longitude }} title={marker.Description} label={{ text: marker.Description, fontSize: '10px', className: 'map__marker' }} />)}
      <motion.button
        variants={logoVariants}
        initial="initial"
        animate="animate"
        transition={logoTransition}
        whileHover="whileHover"
        onClick={props.toggleIsLocationsActive}
        className="map__close_menu_btn"
      >
        <img className="header__menu__header__content__close_menu_btn__image" src={closeMenuIcon} width="" height="" alt="Close Menu" />
      </motion.button>
    </GoogleMap>
  ) : <></>
}

export default memo(Map)
