import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ParallaxProvider } from 'react-scroll-parallax';

import 'App.scss';

import HomeView from "views/Home/Home";
import ForMyEventsView from "views/ForMyEvents/ForMyEvents";
import LandingView from "views/LandingPage/LandingPage";
import PowerView from "views/Power/Power";
import EventsView from "views/Events/Events";
import PartnersView from "views/Partners/Partners";
import TermsAndConditionsView from "views/TermsAndConditions/TermsAndConditions";

function App() {
  const location = useLocation();

  return (
    <ParallaxProvider>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<LandingView/>} />
        <Route path="/home" element={<HomeView />} />
        <Route path="/formyevents" element={<ForMyEventsView />} />
      </Routes>
    </ParallaxProvider>
  );
}

export default App;
