import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import icon from "assets/svg/right-arrow-black.svg"

export default function AdoozyPowerHowItWorksItemLink(props) {
  const controls = useAnimation();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    controls.start((i) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: 1.4 + i * 0.6,
        duration: 0.6,
        type: 'spring',
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    }));
  }, []);

  return (
    <motion.li
      initial={{ x: '-10%', opacity: 0 }}
      animate={controls}
      exit={{ x: '-10%', opacity: 0 }}
      transition={{ duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }}
      custom={props.index}
      className="adoozy_power_how_it_works__list__item__content__links__item"
    >
      {props.name}
      <br/>
      <br/>
      {props.url &&
        <a href={props.url} target="_blank" className="adoozy_power_how_it_works__list__item__content__links__item__button">
          {props.icon &&
            <img className="adoozy_power_how_it_works__list__item__content__links__item__button__icon"  src={isHovered ? props.iconhover : props.icon} alt={props.name} height="40"
            onMouseOver={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}/>
          }
          {!props.icon &&
            <img className="adoozy_power_how_it_works__list__item__content__links__item__button__icon" src={icon} alt={props.name} width="4" height="40" />
          }
        </a>
      }
      {props.action &&
        <button onClick={props.action} className="adoozy_power_how_it_works__list__item__content__links__item__button">
          {props.icon &&
            <img className="adoozy_power_how_it_works__list__item__content__links__item__button__icon" src={isHovered ? props.iconhover : props.icon} height="40"
            onMouseOver={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)} />
          }
          {!props.icon &&
            <img className="adoozy_power_how_it_works__list__item__content__links__item__button__icon" src={icon} alt={props.name} width="4" height="40" />
          }
        </button>
      }
    </motion.li>
  );
}
