// import { useEffect, useState } from 'react'
// import { useMediaQuery } from 'react-responsive'

function preloadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}

export default function PreLoader(imageList) {
  let imagesPreloaded = true
  // const [imagesPreloaded, setImagesPreloaded] = useState(false)
  let images = imageList.large
  // const [images, setImages] = useState(imageList.desktop)

  if(window.innerWidth >= 768 && window.innerWidth <= 991) {
    images = imageList.medium
  }
  
  if (window.innerWidth <= 767) {
    images = imageList.small
  }

  // useEffect(() => {
    let isCancelled = false

    async function effect() {
      if (isCancelled) {
        return
      }

      const imagesPromiseList = []
      for (const i of images) {
        imagesPromiseList.push(preloadImage(i))
      }

      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      imagesPreloaded = true
    }

    effect()

    // return () => {
    //   isCancelled = true
    // }
  // }, [imageList])

  return { imagesPreloaded }
}
