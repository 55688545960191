import { useState, useEffect } from 'react';
import './LandingPage.scss'
import LoadingView from 'views/Home/Loading';
import Header from 'components/HeaderComponent/Header';
import Page from 'components/PageComponent/Page';
import PreLoader from 'components/PreLoaderComponent/PreLoader';
import PowerBankSolution from 'components/LandingPageComponent/Landing';
import { delay } from 'lodash';

function LandingView(props){
    const slug = 'landing';
    const [data, setData] = useState(null);
    const [imagesPreloaded, setImagesPreloaded] = useState(false); 
    const [preloadSrcList, setPreloadSrcList] = useState(null);

    useEffect(() => {
      if(preloadSrcList) {
        setTimeout(() => setImagesPreloaded(PreLoader(preloadSrcList)), 3000);
      }
    }, [preloadSrcList]);
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/preload.cache.php`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setPreloadSrcList(data));
    
        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data));
      }, []);

      const restartCarousel = () => {
        setCarouselPosition(0);
        setTimeout(() => setCarouselPosition(null), 1000);
      }

    if (!imagesPreloaded || !data) {
        return <LoadingView />;
    }

    return(
      <Page className='home__container'>
        <Header restartCarousel={restartCarousel} showHamburger={false} />
        <PowerBankSolution sendData={data.metadata}/>
      </Page>
    );
}

export default LandingView;