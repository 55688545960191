import './Location.scss';

export default function Location(prop) {

    const goTo = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    };

    return(
        <div className="adoozy-location">
            <h2 className="adoozy-location__title">Locations</h2>
            <div className="adoozy-location__header">
                <img src={prop.main_image.sizes.large}/>
            </div>
            <div className="adoozy-location__content">
                <div className="steps-grid2">
                    {prop.actions.map((item, index) => (
                        <div className="step-card2__image" key={index}>
                                 {item.action &&
                                    <a onClick={() => {prop.hasOwnProperty(item.action) && typeof prop[item.action] === 'function' && prop[item.action]();}}>
                                        <img src={item.image.sizes.large} />
                                    </a>
                                }
                                {item.link &&
                                    <a onClick={e => goTo()}>
                                        <img src={item.image.sizes.large} />
                                    </a>
                                }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}