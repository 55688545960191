import './AdoozyCollect.scss';

export default function AdoozyCollect(props) {


    return(
        <div className="adoozy-collect">
            <h2 className="adoozy-collect__title">Adoozy Collect</h2>
            <div className="adoozy-collect__header">
                <img src={props.main_image.sizes.large} alt="Uber Delivery" />
            </div>
            <div className="adoozy-collect__content">
                <h1>{props.heading}</h1>
                <p>{props.sub_heading}<br/>
                {props.sub_heading2}</p>
                <div className="steps-grid3">
                    {props.items.map((item, index) => (
                        <div className="step-card3" key={index}>
                            <div className="step-card3__image">
                                <img src={item.image.sizes.large} alt={item.title} />
                            </div>
                            <div className="step-card3__content2">
                                <h2>{item.title}</h2>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}