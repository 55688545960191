import { motion } from "framer-motion";
import { useMediaQuery } from 'react-responsive';

import googleDownloadIcon from 'assets/svg/google-download-icon.svg';
import appleDownloadIcon from 'assets/svg/apple-download-icon.svg';

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function AdoozyPowerPricingItem(props) {
  const isDesktop = useMediaQuery({ minWidth: 992 })

  return (
    <li className="adoozy_power_pricing__list__items__item">
      <motion.div
        initial={isDesktop ? "hidden" : null}
        whileInView={isDesktop ? "visible" : null}
        viewport={isDesktop ? { once: true } : null}
        transition={isDesktop ? transition : null}
        variants={isDesktop ? {
          visible: { opacity: 1, y: 0 },
          hidden: { y: '100%', opacity: 0 }
        } : null}
        className="adoozy_power_pricing__list__items__item__card__info"
      >
        <img className="adoozy_power_pricing__list__items__item__card__info__image" src={props.image.sizes.large}/>
      </motion.div>
    </li>
  );
}
