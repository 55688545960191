import { useState, useEffect, React, useRef } from 'react';

import './CommingSoon.scss';

export default function CommingSoon(props){
    const vidRef=useRef();
    useEffect(() => { vidRef.current.play(); },[]);

    const videoUrl = props.sendData.video && props.sendData.video[0] ? props.sendData.video[0].url : '';

    return (
        <div className="video-container">
          {videoUrl ? (
            <video className='background-video' loop muted autoPlay src={videoUrl} type="video/mp4" ref={vidRef} />
          ) : (
            <p>No video available</p> // Fallback if no video URL is provided
          )}
        </div>
    );
}