import { useState, useEffect, useRef } from 'react';
import { AnimatePresence } from "framer-motion";

import './Home.scss';

import Header from 'components/HeaderComponent/Header';
import HomeCarousel from 'components/HomeCarouselComponent/HomeCarousel';
import Page from 'components/PageComponent/Page';
import PreLoader from 'components/PreLoaderComponent/PreLoader';
import PageMenu from 'components/PageMenuComponent/PageMenu';
import Loading from 'components/LoadingComponent/Loading';
import AdoozyPowerHowItWorks from 'components/AdoozyPowerHowItWorksComponent/AdoozyPowerHowItWorks';
import AdoozyPowerProducts from 'components/AdoozyPowerProductsComponent/AdoozyPowerProducts';
import AdoozyCollect from 'components/AdoozyCollectComponent/AdoozyCollect';
import Location from 'components/LocationComponent/Location';
import AdoozyPowerPricing from 'components/AdoozyPowerPricingComponent/AdoozyPowerPricing';
import CustomerService from 'components/CustomerServiceComponent/CustomerService';
import debounce from 'lodash.debounce';

function HomeView(props) {
  const [preloadSrcList, setPreloadSrcList] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const slug = 'home';
  const [imagesPreloaded, setImagesPreloaded] = useState(false); 
  const [data, setData] = useState(null);
  const [carouselPosition, setCarouselPosition] = useState(null);
  const [products, setProducts] = useState(null);
  const [showCarousel, setShowCarousel] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

   // Scroll handler
   const handleScroll = debounce(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 100 && showCarousel) {
      setShowCarousel(false);
    } else if (scrollTop <= 100 && !showCarousel) {
      setShowCarousel(true);
    }
    setLastScrollTop(scrollTop);
  }, 100); // debounce for 100ms


  const howItWorksRef = useRef(null),
    productsRef = useRef(null),
    adoozyCollectRef = useRef(null),
    locationsRef = useRef(null), 
    pricingRef = useRef(null),
    customerServiceRef = useRef(null);

  const sections = [
    {
      ref: howItWorksRef,
      name: "How it Works"
    },
    {
      ref: productsRef,
      name: "Products"
    },
    {
      ref: adoozyCollectRef,
      name: "Adoozy Collect"
    },
    {
      ref: locationsRef,
      name: "Locations"
    },
    {
      ref: pricingRef,
      name: "Pricing"
    },
    {
      ref: customerServiceRef,
      name: "Customer Service"
    }
  ];

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop, showCarousel]);
  
  useEffect(() => {
    if(preloadSrcList) {
      setTimeout(() => setImagesPreloaded(PreLoader(preloadSrcList)), 3000);
    }
  }, [preloadSrcList]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/preload.cache.php`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setPreloadSrcList(data));

    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setData(data));

    fetch(`${process.env.REACT_APP_REST_API_URL}/post-type/products`, {
          method: 'GET',
          mode: 'cors'
      }).then(response => response.json())
          .then(data => setProducts(data));
    }, []);

  const restartCarousel = () => {
    setCarouselPosition(0);
    setTimeout(() => setCarouselPosition(null), 1000);
  }

  if (!imagesPreloaded || !data) {
    return <Loading />;
  }

  return (
    <Page>
       <Header restartCarousel={restartCarousel} showHamburger={true} sections={sections} />
        <HomeCarousel slides={data.metadata.carousel} carouselPosition={carouselPosition} sections={sections} />
         <PageMenu sections={sections} />
          <div ref={howItWorksRef} />
          <AdoozyPowerHowItWorks items={data.metadata.how_it_works} image={data.metadata.how_it_works_image} questions={data.metadata.how_is_it_work_main} />
          <div ref={productsRef}></div>
          <AdoozyPowerProducts items={products} />
          <div ref={adoozyCollectRef}></div>
          <AdoozyCollect
            items={data.metadata.adoozy_collect}
            main_image={data.metadata.main_image}
            heading={data.metadata.heading}
            sub_heading={data.metadata.sub_heading}
            sub_heading2={data.metadata.sub_heading2}
            adoozy_logo={data.metadata.adoozy_logo}
            uber_logo={data.metadata.uber_logo}
          />
          <div ref={locationsRef}></div>
          <Location actions={data.metadata.actions} main_image={data.metadata.image} />
          <div ref={pricingRef}></div>
          <AdoozyPowerPricing items={data.metadata.pricing} />
          <div ref={customerServiceRef}></div>
          <CustomerService items={data.metadata} />
    </Page>
  );
}

export default HomeView;