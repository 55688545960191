import React from 'react';
import { useEffect, useState } from "react";
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './Landing.scss';

const PowerBankSolution = (props) => {

  const controls = useAnimation();
  const navigate = useNavigate();

  const goTo = (link) => {
    navigate(link);
  };

  useEffect(() => {
    controls.start((i) => ({
      y: 0,
      opacity: 1,
      duration: 0.5,
      transition: { delay: 3+i * 0.5 }
    }));
  }, [controls]);

  return (
    <div className='body'>
    <div className="container">
      <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 3 }}>
      <h1 className="Textheader">The Preferred Power Bank Solution</h1>
      <p className="subHeader">I'm interested in using the service</p>
      <div className="optionsContainer">
        {
          props.sendData.land.map((item, index) =>
            <div className="option" onClick={e => goTo(item.link)} key={index}>
              <img 
                src={item.image.sizes.large}
                alt={item.label}
                className="image"
              />
              <div className="optionText">{item.label}</div>
              <div className="arrowButton">
                  <img src={item.icon.sizes.large}/>
              </div>
            </div>
          )
        }
      </div>
      </motion.div>
    </div>
    </div>
  );
};
  
export default PowerBankSolution;
