import './CustomerService.scss'
import { motion } from "framer-motion";
import { Mobile, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';
import { form } from "data/Partners.js";

import speaker from 'assets/img/social_media_speaker_icon.png';
import Form from 'components/FormComponent/Form';

const transition = {
    duration: 0.4,
    ease: [0.43, 0.13, 0.23, 0.96]
}

    const isEmail = (detail) => {
      // Regex to test if it's an email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(detail);
    };
  
    const isPhoneNumber = (detail) => {
      // Regex to test if it's a phone number (basic check for digits, dashes, spaces, etc.)
      const phoneRegex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
      return phoneRegex.test(detail);
    };

export default function CustomerService(props) {
    return(
        <div className="adoozy-customer_service">
            <h2 className="adoozy-customer_service__title">Customer Service</h2>
            <div className="adoozy-customer_service__header">
                <img src={props.items.customer_service_image.sizes.large}/>
            </div>
            <p className="adoozy-customer_service__subheading">
                <motion.span
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={transition}
                variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { x: '-100%', opacity: 0 }
                }}
                >
                {props.items.customer_service_heading}
                <br/>
                {props.items.customer_service_heading_line2}
                
                </motion.span>
            </p>
        <div className="adoozy-customer_service__content">
            <h3>{props.items.customer_service_sub_hdeaing}</h3>
            <div className="adoozy-customer_service__steps-grid">
                {props.items.customer_servicecontact_details.map((item, index) => (
                <div key={index} className="adoozy-customer_service__items" keys={index}>
                    <img src={item.image.sizes.large} alt={item.label} />
                    {item.label}
                    {isEmail(item.detail) ? (
                        <a href={`mailto:${item.detail}`} target='_blank'>{item.detail}</a>
                    ) : index === 1 && isPhoneNumber(item.detail) ? (
                        <a href={`https://wa.me/${item.detail.replace(/\s+/g, '')}`} target='_blank'>{item.detail}</a>
                    ) : isPhoneNumber(item.detail) ? (
                        <a href={`tel:${item.detail}`} target='_blank'>{item.detail}</a>
                    ) : (
                        <span>{item.detail}</span>
                    )}
                </div>
                ))}
            </div>

            <div className="adoozy-customer_service__social">
                <img src={speaker} alt="Speaker" />
                <p>@adoozylifestyle</p> 
                {props.items.customer_service_social.map((item, index) => (
                   <a href={item.link} target="_blank" rel="noopener noreferrer" key={index}>
                     <img src={item.image.sizes.large} alt={item.label} />
                    </a>
                 ))}
            </div>
        </div>
            <Mobile>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '70px 0', backgroundColor: '#1C1C1C' }}>
                <Form subject={'New Partners Enquiry'} title={form.title} submit_label={form.submit_label} fields={form.fields} />
            </div>
            </Mobile>
            <Desktop>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '146px 0 165px', backgroundColor: '#1C1C1C' }}>
                <Form subject={'New Partners Enquiry'} title={form.title} submit_label={form.submit_label} fields={form.fields} />
            </div>
            </Desktop>
   </div>
    );
}

