import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Mobile, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

const transition = {
  duration: 1,
  // type: 'spring',
  ease: [0.43, 0.13, 0.23, 0.96]
}

const textTransition = {
  duration: 1,
  delay: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function HomeCarouselListItem(props) {
  const navigate = useNavigate();

  const goToSection = (to, action) => {
    /*if(action) {
      action();
    } else if(to) {
      navigate(to);
    }*/
  }

  const onWheel = (event) => {
    goToSection(props.to, props.action);
  };

  const onWheelThrottled = useMemo(() => _.debounce(onWheel, 400), []);

  return (
    <AnimatePresence exitBeforeEnter>
      {props.is_active &&
        <motion.li
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { ...transition, delay: 1 } }}
          transition={transition}
          onWheel={onWheelThrottled}
          className="home_carousel__list__item"
        >
           <Mobile>
           <motion.video
              src={props.videomobile.url}
              alt=""
              width=""
              height=""
              preload="auto"
              loop 
              muted
              autoPlay>
            </motion.video>
          </Mobile>
          <Desktop>
            <motion.video
              className="home_carousel__list__item__image"
              src={props.video.url}
              alt=""
              width=""
              height=""
              preload="auto"
              loop 
              muted
              autoPlay>
            </motion.video>
          </Desktop>
  
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { ...textTransition, delay: 0 } }}
            transition={textTransition}
            className="home_carousel__list__item__title"
          >
            <motion.em initial={{ marginLeft: '-50%' }} animate={{ marginLeft: 0 }} exit={{ marginLeft: '50%', transition: { ...textTransition, delay: 0 } }} transition={textTransition}>{props.first_line}</motion.em>
            <motion.span initial={{ y: '100%', opacity: 0 }} animate={{ y: 0, opacity: 1, transition: {...textTransition, delay: 2, type: 'spring', stiffness: 50 } }} exit={{ marginLeft: '-50%', transition: { ...textTransition, delay: 0 } }} transition={textTransition}>{props.second_line}</motion.span>
          </motion.h1>
        </motion.li>
      }
    </AnimatePresence>
  );
}
